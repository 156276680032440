import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Treatments from "../components/Treatments/treatments"
import SEO from "../components/seo"

function Zabiegi({ data }) {
  return (
    <Layout>
      <SEO title="Zabiegi" description={data.strapiMetaTag.opis_strony} keywords={data.strapiMetaTag.tagi}/>

      <Treatments
        title_photo={data.title_photo}
        treatments={data.treatments.edges}
      />
    </Layout>
  )
}

export default Zabiegi

export const query = graphql`
  query zabiegi {
    title_photo: file(relativePath: { eq: "zabiegi.png" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapiMetaTag(nazwa_strony: {eq: "zabiegi"}) {
      opis_strony
      tagi
    }
    treatments: allStrapiZabieg {
      edges {
        node {
          id
          Cena
          Nazwa
          Opis
          pozycja_na_stronie
          Zdjecie {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
